<script lang="ts">
  import { MessageSquareWarning } from "lucide-svelte"
  import Alertbox from "./alertbox.svelte"
  let { children } = $props()
</script>

<Alertbox
  Icon={MessageSquareWarning}
  title="Important"
  color="indigo-500"
  customEdgeColor="indigo-300"
  {children}
></Alertbox>
